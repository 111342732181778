$ease-in--quint: cubic-bezier(0.22, 1, 0.36, 1);
$ease-out--quint: cubic-bezier(0.83, 0, 0.17, 1);

@mixin transition-in(
  $target: all,
  $duration: 400ms,
  $target2: false,
  $duration2: 400ms
) {
  @if $target2 == false {
    transition: $target $duration $ease-in--quint;
  } @else {
    transition: $target $duration $ease-in--quint,
      $target2 $duration2 $ease-in--quint;
  }
}

@mixin transition-out(
  $target: all,
  $duration: 400ms,
  $target2: false,
  $duration2: 400ms
) {
  @if $target2 == false {
    transition: $target $duration $ease-out--quint;
  } @else {
    transition: $target $duration $ease-out--quint,
      $target2 $duration2 $ease-out--quint;
  }
}

@keyframes shimmer {
  0% {
    background-position: -800px 0;
  }
  100% {
    background-position: 800px 0;
  }
}

@mixin animate--shimmer {
  position: relative;
  opacity: 0.85;
  overflow: hidden;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 10%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 90%
    );
    background-size: 800px 100%;
    animation: shimmer 2.5s infinite linear;
    pointer-events: none;
  }
}
