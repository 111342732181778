@mixin layout--large {
  @media screen and (max-width: $breakpoint--large) {
    @content;
  }
}

@mixin layout--medium {
  @media screen and (max-width: $breakpoint--medium) {
    @content;
  }
}

@mixin layout--small {
  @media screen and (max-width: $breakpoint--small) {
    @content;
  }
}

@mixin font--variants {
  strong,
  b {
    font-weight: $font-weight--bold;
  }
  em {
    font-style: italic;
  }
  a {
    text-decoration: underline;
  }
}

@mixin font--copy {
  font-family: $font-family--copy;
  font-weight: $font-weight--regular;
  font-size: $font-size--copy;
  line-height: $line-height--copy;
  @include font--variants;
}

@mixin font--display {
  font-family: $font-family--display;
  font-weight: $font-weight--bold;
  font-size: $font-size--display;
  line-height: $line-height--display;
}

@mixin font--action {
  font-family: $font-family--copy;
  font-weight: $font-weight--medium;
  font-size: $font-size--hint;
  line-height: $line-height--action;
  text-transform: uppercase;
}
