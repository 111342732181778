$color--white: #FFFFFF;

$color--primary--lightest: #F3FBFD;
$color--primary--light: #5C7192;
$color--primary: #485973;
$color--primary--dark: #575D68;
$color--primary--darkest: #2D2F33;

$color--accent--lightest: #F3FBFD;
$color--accent--light: #59B0EE;
$color--accent: #348DDF;
$color--accent--dark: #106CAE;
$color--accent--darkest: #155482;

$color--brand--lightest: #F8FFFD;
$color--brand--light: #03CB9A;
$color--brand: #45A78F;
$color--brand--dark: #4C4F53;
$color--brand--darkest: #253330;

$color--positive--lightest: #FDFFFE;
$color--positive--light: #2EE080;
$color--positive: #19C869;
$color--positive--dark: #2F9E4E;
$color--positive--darkest: #036850;

$color--negative--lightest: $color--white;
$color--negative--light: #EC5838;
$color--negative: #DB4141;
$color--negative--dark: #BA2B0C;
$color--negative--darkest: #461A14;

$color--warn--lightest: #FFFFFD;
$color--warn--light: #F8E751;
$color--warn: #F2E03E;
$color--warn--dark: #DAD20E;
$color--warn--darkest: #494533;

$color--gray--lightest: #FEFEFE;
$color--gray--light: #DAE0E2;
$color--gray: #747981;
$color--gray--dark: #5B6163;
$color--gray--darkest: #1A1A1A;

$color--background--lightest: $color--white;
$color--background--light: #FBFBFB;
$color--background: #FCFCFC;
$color--background--dark: #E1E9E9;
$color--background--darkest: #A3ABAF;
